<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'

export default {
  name: 'base-button-round',
  components: {
    BaseIcon,
    BaseLoader,
  },
  props: {
    align: {
      type: String,
      default: '',
      validator(value) {
        return !value || /(right|left)$/.test(value)
      },
    },
    look: {
      type: String,
      default: 'filled',
      validator: (v) => !v || /(filled|plain|outlined)$/.test(v),
    },
    color: {
      type: String,
      default: 'primary',
      validator(value) {
        return (
          !value || /(primary|success|warning|error|info|white)$/.test(value)
        )
      },
    },
    iconSize: {
      type: String,
      default: 'md',
      validator: (v) => !v || /xs|sm|md|lg|xl/.test(v),
    },
    iconColor: {
      type: String,
      default: 'white',
      validator: (v) => !v || /(white|primary)$/.test(v),
    },
    size: {
      type: String,
      default: 'md',
      validator: (v) => !v || /md|lg/.test(v),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: true,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    showTextOnMobile: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: [Number, String],
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    alt: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    loaderColor() {
      if (this.iconColor === 'primary' && this.color === 'white')
        return this.color
      return this.look === 'filled'
        ? 'var(--color-zinc-100)'
        : 'var(--color-primary)'
    },
    label() {
      return `${this.title} ${this.alt}`
    },
  },
}
</script>

<template>
  <button
    ref="button"
    :disabled="disabled || loading"
    class="flex cursor-pointer items-center gap-2.5 leading-none outline-none disabled:pointer-events-none disabled:cursor-default"
    :class="{
      'hover:opacity-75 focus:opacity-75 active:opacity-75 disabled:opacity-100':
        look === 'filled' || look === 'plain',
      'text-primary': color === 'primary',
      'text-fg-success': color === 'success',
      'text-fg-warning': color === 'warning',
      'text-fg-error': color === 'error',
      'text-fg-info': color === 'info',
      'text-white': color === 'white',
    }"
    :aria-label="label"
    :tabindex="disabled ? -1 : tabindex"
    v-on="$listeners"
  >
    <span
      v-if="text || $slots.default"
      class="self-center truncate text-sm font-bold sm:text-base sm:leading-none"
      :class="{
        'order-1': align === 'left',
        'hidden sm:block': !showTextOnMobile,
        'opacity-75': disabled,
      }"
    >
      <template v-if="text">
        {{ text }}
      </template>
      <slot v-else />
    </span>
    <span
      class="relative flex shrink-0 items-center justify-center overflow-hidden rounded-full text-sm leading-none focus:shadow sm:text-base sm:leading-none"
      :class="{
        'h-[35px] w-[35px]': size === 'md',
        'h-10 w-10': size === 'lg',
        'border bg-transparent text-primary hover:border-primary':
          look === 'outlined',
        'text-primary': iconColor === 'primary',
        'text-white': iconColor === 'white',
        'bg-primary': color === 'primary' && look === 'filled',
        'bg-fg-success': color === 'success' && look === 'filled',
        'bg-fg-warning': color === 'warning' && look === 'filled',
        'bg-fg-error': color === 'error' && look === 'filled',
        'bg-fg-info': color === 'info' && look === 'filled',
        'bg-white': color === 'white' && look === 'filled' && !loading,
        'opacity-75 shadow-none': disabled || loading,
        'bg-disabled':
          (disabled && !(color === 'white' && look === 'filled')) || loading,
      }"
    >
      <base-icon
        v-if="icon && !loading"
        :svg="icon"
        :size="iconSize"
        :alt="alt"
        class="z-1"
      />
      <span
        v-if="loading"
        ref="loader"
        class="absolute inset-0 z-10 flex h-full w-full items-center justify-center"
      >
        <base-loader ref="the-loader" size="xs" :color="loaderColor" />
      </span>
    </span>
  </button>
</template>
